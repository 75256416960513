import { useMemo } from "react";
import classnames from "classnames";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { useTable, useSortBy, Column } from "react-table";
import { NoTableData } from "@ugg/shared/components/common/NoTableData";
import { numberWithCommas } from "@ugg/shared/utils/math";
import { getKdaColorClassName } from "@ugg/shared/utils/kda";
import { ArenaModeType } from "@ugg/shared/utils/queue-type-helpers";
import { formatSummonerChampionStats, ChampionStatsInfo } from "@ugg/shared/api/data-parser/summoners/summoner-champion-stats";
import { BasicChampionPerformance } from "@ugg/shared/api/requests/summoner-profiles/player-statistics";

interface ArenaChampionStatsTableProps {
  data: BasicChampionPerformance[];
  arenaModeType: ArenaModeType;
}

export function ArenaChampionStatsTable(props: ArenaChampionStatsTableProps) {
  const { getChampionImg, getChampionName } = getRiotAssetsContext();

  const { data, arenaModeType } = props;

  const tableData = useMemo(() => {
    const champions = formatSummonerChampionStats(data || []);
    champions.sort((a, b) => {
      const matchDiff = b.played.totalMatches - a.played.totalMatches;
      if (matchDiff > 0) {
        return 1;
      } else if (matchDiff < 0) {
        return -1;
      } else {
        const champNameA = getChampionName(a.championId);
        const champNameB = getChampionName(b.championId);
        return champNameA.localeCompare(champNameB);
      }
    });

    return champions;
  }, [data]);

  const columns = useMemo<Column<ChampionStatsInfo>[]>(
    () => [
      {
        id: "rank",
        accessor: "totalMatches",
        Header: <span>Rank</span>,
        responsiveClassName: "min-w-[30px] max-w-[60px] max-xs:max-w-[40px]",
        headerClassName: "rank-cell max-xs:[&>*]:hidden",
        className: "rank-cell",
        Cell: ({ index }) => <span>{index + 1}</span>,
        sortType: (a, b) => {
          const matchDiff = b.values.totalMatches - a.values.totalMatches;
          if (matchDiff > 0) {
            return 1;
          } else if (matchDiff < 0) {
            return -1;
          } else {
            const champNameA = getChampionName(a.values.championId);
            const champNameB = getChampionName(b.values.championId);
            return champNameA.localeCompare(champNameB);
          }
        },
      },
      {
        accessor: "championId",
        Header: <div className="flex justify-center flex-1">Champion</div>,
        responsiveClassName: `
          min-w-[120px]  max-xs:min-w-[100px] max-[450px]:min-w-[50px]
        `,
        headerClassName: "champion-cell max-xs:[&>*]:hidden",
        className: "champion-cell",
        sortType: (a, b) => {
          const champNameA = getChampionName(a.values.championId);
          const champNameB = getChampionName(b.values.championId);
          return champNameA.localeCompare(champNameB);
        },
        Cell: (props) => {
          const championImg = getChampionImg(props.value);
          const championName = getChampionName(props.value);

          return (
            <div className="flex items-center w-full max-[450px]:justify-center">
              <div className="relative flex-none w-[30px] h-[30px] rounded-[3px] overflow-hidden max-xs:w-[24px] max-xs:h-[24px]">
                <img className="absolute w-full h-full scale-[1.1]" src={championImg} />
              </div>
              <span className="text-white ml-[12px] text-[13px] font-bold truncate max-[450px]:hidden">{championName}</span>
            </div>
          );
        },
      },
      {
        accessor: "firstRate",
        Header: "Top 1",
        responsiveClassName: "min-w-[60px] max-w-[80px] max-xs:max-w-[70px]",
        headerClassName: "win-rate-cell",
        className: "win-rate-cell",
        sortDescFirst: true,
        Cell: (props) => <span>{props.value}%</span>,
      },
      {
        accessor: "top4",
        Header: arenaModeType === 16 ? "Top 4" : "Top 2",
        responsiveClassName: "min-w-[60px] max-w-[80px] max-xs:max-w-[70px]",
        headerClassName: "win-rate-cell",
        className: "win-rate-cell",
        sortDescFirst: true,
        Cell: (props) => <span>{props.value}%</span>,
      },
      {
        accessor: "avgPlacement",
        Header: () => <span className="whitespace-nowrap">Avg. Place</span>,
        responsiveClassName: "min-w-[70px] max-w-[80px]",
        headerClassName: "win-rate-cell",
        className: "win-rate-cell",
        sortDescFirst: true,
        Cell: (props) => <span>{props.value}</span>,
      },
      {
        accessor: "kda",
        Header: "KDA",
        responsiveClassName: "min-w-[100px] max-w-[100px] max-[600px]:!hidden",
        headerClassName: "kda-cell",
        className: "kda-cell",
        sortDescFirst: true,
        sortType: (a, b) => {
          return a.values.kda.ratio - b.values.kda.ratio;
        },
        Cell: ({ value }) => (
          <div className="flex flex-col items-center">
            <div className={getKdaColorClassName(value.ratio)}>
              <strong>{value.ratio}</strong>
            </div>
            <span className="text-[11px] text-lavender-50">
              <strong>{value.avgKills}</strong>
              <span className="text-lavender-300"> / </span>
              <strong>{value.avgDeaths}</strong>
              <span className="text-lavender-300"> / </span>
              <strong>{value.avgAssists}</strong>
            </span>
          </div>
        ),
      },
      {
        accessor: "maxKills",
        Header: "Max Kills",
        responsiveClassName: "min-w-[85px] max-w-[85px] max-[700px]:!hidden",
        headerClassName: "max-kills-cell",
        className: "max-kills-cell",
        sortDescFirst: true,
        Cell: (props) => <span>{props.value}</span>,
      },
      {
        Header: "Max Death",
        responsiveClassName: "min-w-[85px] max-w-[85px] max-[900px]:!hidden",
        headerClassName: "max-deaths-cell",
        className: "max-deaths-cell",
        accessor: "maxDeaths",
        sortDescFirst: true,
        Cell: (props) => <span>{props.value}</span>,
      },
      {
        accessor: "avgDamage",
        Header: "Damage",
        responsiveClassName: "min-w-[90px] max-w-[90px] max-[800px]:!hidden",
        headerClassName: "damage-cell",
        className: "damage-cell",
        sortDescFirst: true,
        Cell: (props) => <span>{numberWithCommas(props.value)}</span>,
      },
      {
        accessor: "doubleKills",
        Header: "Double Kills",
        responsiveClassName: "min-w-[90px] max-w-[90px] max-[1075px]:!hidden",
        headerClassName: "",
        className: "",
        sortDescFirst: true,
        Cell: (props) => {
          return props.value === 0 ? (
            <span style={{ color: "#4F4F60" }}>&mdash;</span>
          ) : (
            <strong style={{ color: "#cddcfe" }}>{numberWithCommas(props.value)}</strong>
          );
        },
      },
      {
        Header: "Matches",
        accessor: "totalMatches",
        responsiveClassName: "xxxs:min-w-[60px] max-xs:max-w-[70px] xs:min-w-[80px] max-w-[90px] max-[500px]:!hidden",
        headerClassName: "matches",
        className: "matches",
        sortDescFirst: true,
        Cell: ({ value }) => <span>{numberWithCommas(value)}</span>,
      },
    ],
    [data],
  );

  const columnStyle = () => ({
    flex: `1 1 100%`,
  });

  const { state, getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setHiddenColumns } = useTable(
    {
      columns,
      data: tableData,
      disableSortRemove: true,
    },
    useSortBy,
  );

  if (rows.length === 0) {
    return (
      <div className="content-section">
        <NoTableData className="my-[48px]" />
      </div>
    );
  }

  return (
    <div {...getTableProps({ className: "content-section ReactTable ugg-table-2 w-full" })}>
      <div className="rt-thead -header">
        {headerGroups.map((headerGroup, index) => (
          <div {...headerGroup.getHeaderGroupProps({ className: "rt-tr" })}>
            {headerGroup.headers.map((column, index) => {
              const thClassNames = classnames([
                "rt-th",
                column.headerClassName,
                column.responsiveClassName,
                column.isSorted && !column.isSortedDesc ? "-sort-asc" : "",
                column.isSorted && column.isSortedDesc ? "-sort-desc" : "",
              ]);

              return (
                <div className={thClassNames} {...column.getHeaderProps(column.getSortByToggleProps({ style: columnStyle() }))}>
                  {column.render("Header")}
                </div>
              );
            })}
          </div>
        ))}
      </div>
      <div {...getTableBodyProps({ className: "rt-tbody" })}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <div key={i} className="rt-tr-group">
              <div {...row.getRowProps({ className: "rt-tr" })}>
                {row.cells.map((cell, index) => {
                  const tdClassNames = classnames("rt-td", cell.column.responsiveClassName, cell.column.className, {
                    sorted: index !== 0 && cell.column.isSorted,
                    "is-in-odd-row": i % 2 === 0,
                  });

                  return (
                    <div {...cell.getCellProps({ className: tdClassNames, style: columnStyle() })}>
                      {cell.render("Cell", { index: i })}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
