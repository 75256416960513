import { QueueTypeN } from "@ugg/shared/utils/queue-type-helpers";

export const QUEUE_TYPE_OPTIONS = [
  { label: "All Matches", value: "all" },
  { label: "Ranked Solo", value: "ranked_solo" },
  { label: "Ranked Flex", value: "ranked_flex" },
  { label: "ARAM", value: "aram" },
  { label: "Arena", value: "arena" },
  { label: "Quickplay", value: "quickplay" },
  { label: "Swiftplay", value: "swiftplay" },
  { label: "Normal Draft", value: "normal_draft" },
  { label: "Clash", value: "clash" },
];

export const QUEUE_TYPE_MAP = {
  all: [] as number[],
  ranked_solo: [QueueTypeN.RANKED_SOLO],
  ranked_flex: [QueueTypeN.RANKED_FLEX],
  aram: [QueueTypeN.ARAM],
  arena: [QueueTypeN.ARENA],
  quickplay: [QueueTypeN.QUICKPLAY],
  swiftplay: [QueueTypeN.SWIFTPLAY],
  normal_draft: [QueueTypeN.NORMAL_DRAFT],
  clash: [QueueTypeN.CLASH],
};

export const defaultMatchFilters = {
  queueType: QUEUE_TYPE_OPTIONS[0].value as keyof typeof QUEUE_TYPE_MAP,
  championId: "all" as number | "all",
  duoRiotId: "all" as (string & {}) | "all",
};
