import { renderToString } from "react-dom/server";
import { useEffect, useMemo, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { getProBuildRankName } from "@ugg/shared/utils/rank-helpers";
import { getLPGraphTierColor } from "./lp-graph-helpers";
import { getFilteredPlotbands, getPlotLines } from "./lp-plotbands";
import { ReactComponent as TriangleArrowDown } from "@ugg/shared/assets/svg/triangle-arrow-down.svg";

interface LPGraphProps {
  series: any;
  min: number | undefined;
  max: number | undefined;
  tier: string;
  minTier: string;
  maxTier: string;
}

export default function LPGraph(props: LPGraphProps) {
  const { getChampionImg } = getRiotAssetsContext();
  const redrawTimeout = useRef<NodeJS.Timeout>();
  const chartRef = useRef<HighchartsReact.RefObject>(null);
  const { series, min, max, tier, minTier, maxTier } = props;

  const zones = useMemo(() => {
    return (series || []).map((point: any) => ({
      value: point.x + 1,
      color: getLPGraphTierColor(point.data.tier),
      fillColor: "transparent",
    }));
  }, [series]);

  useEffect(() => {
    if (chartRef.current && chartRef.current.chart) {
      redrawTimeout.current && clearTimeout(redrawTimeout.current);
      redrawTimeout.current = setTimeout(() => {
        chartRef.current?.chart?.redraw();
      }, 300);
    }
  }, [series]);

  const options: Highcharts.Options = {
    chart: {
      type: "spline",
      backgroundColor: "#11112A",
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      animation: false,
      events: {
        load: function () {
          this.redraw();
        },
      },
    },
    series: [
      {
        type: "spline",
        data: series,
        lineWidth: 1,
        states: {
          hover: {
            lineWidthPlus: 0,
            halo: {
              size: 0,
            },
          },
        },
        marker: {
          enabled: false,
          lineColor: "#11112a",
          lineWidth: 2,
          radius: 6,
          states: {
            hover: {
              lineWidthPlus: 0,
              radiusPlus: 0,
            },
          },
        },
        color: {
          linearGradient: { x1: -1, y1: -1, x2: 1, y2: 1 },
          stops: [
            [0, "#313160"],
            [1, "#191937"],
          ],
        },
        zoneAxis: "x",
        zones,
      },
    ],
    title: {
      text: "",
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      distance: 18,
      backgroundColor: "transparent",
      borderColor: "transparent",
      borderWidth: 0,
      headerFormat: "",
      useHTML: true,
      formatter: function () {
        // @ts-ignore
        const { data, diff } = this.point;
        const championImg = getChampionImg(data.championId);
        return renderToString(
          <div className="lp-graph_tooltip">
            {championImg && (
              <div className="champion-img">
                <img src={championImg} />
              </div>
            )}
            <div className="flex flex-col">
              <span>
                <span style={{ color: "white", fontWeight: 700 }}>{getProBuildRankName(data.tier, data.rank, true)}</span>
                <span style={{ color: "#cddcfe", fontWeight: 500 }}> {data.lp} LP</span>
              </span>
              <div className="flex items-center" style={{ marginTop: 2 }}>
                {diff === 0 ? (
                  <strong style={{ color: "#5f5f7b" }}>&plusmn;</strong>
                ) : (
                  <TriangleArrowDown className={`triangle-svg ${diff > 0 ? "gain" : "loss"}`} />
                )}
                <span style={{ marginLeft: 4, color: "#5f5f7b", fontWeight: 500 }}>{Math.abs(diff)}</span>
              </div>
            </div>
          </div>,
        );
      },
    },
    xAxis: {
      labels: {
        enabled: false,
      },
      tickLength: 0,
      visible: false,
      minPadding: 0,
      maxPadding: 0,
    },
    yAxis: {
      labels: {
        enabled: false,
      },
      min: min !== undefined && min - 100 > 0 ? min - 100 : min,
      max: max !== undefined ? max + 100 : max,
      minPadding: 0,
      maxPadding: 0,
      tickInterval: 100,
      tickLength: 0,
      gridLineWidth: 0,
      plotLines: getPlotLines(maxTier),
      plotBands: min === undefined || max === undefined ? [] : getFilteredPlotbands(min, max, tier, maxTier),
    },
  };

  const chartCallback = (chart: Highcharts.Chart) => {
    if (chart) {
      chart.reflow();
    }
  };

  return (
    <div className="chart-container">
      {!series || series.length === 0 ? (
        <div className="no-lp-message">Not enough Ranked data this season to display LP graph.</div>
      ) : (
        <HighchartsReact
          highcharts={Highcharts}
          ref={chartRef}
          options={options}
          containerProps={{
            style: { width: "100%", height: "100%" },
          }}
          callback={chartCallback}
        />
      )}
    </div>
  );
}
