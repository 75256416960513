import classNames from "classnames";
import { Link } from "react-router-dom";
import { TooltipContainer } from "@outplayed/tooltips";
import { usePlayerOverallRanking } from "@ugg/shared/api/requests/leaderboards/ladder-ranking";
import { getLeaderboardsUrl } from "@ugg/shared/routes/app-routes";
import { numberWithCommas, round } from "@ugg/shared/utils/math";

interface LadderRankingProps {
  className?: string;
  riotUserName: string;
  riotTagLine: string;
  region: string;
  queueType?: number;
}

export function LadderRanking(props: LadderRankingProps) {
  const { className, riotUserName, riotTagLine, region, queueType = 420 } = props;

  const { data, loading, error } = usePlayerOverallRanking(riotUserName, riotTagLine, region, queueType);
  const { overallRanking } = data || {};
  const { overallRanking: ladderRanking, totalPlayerCount } = overallRanking || {};

  if (!data || loading) {
    return null;
  } else if (!ladderRanking || !totalPlayerCount) {
    return null;
  }

  const rankPercentile = (() => {
    let percentile = (ladderRanking / totalPlayerCount) * 100;
    percentile = percentile > 1 ? round(percentile, 1) : round(percentile, 4);
    return percentile;
  })();

  return (
    <div className={classNames("h-[12px] text-[10px] font-medium empty:h-0", className)}>
      {ladderRanking && (
        <TooltipContainer tooltipInfo={"View your current ladder ranking"}>
          <Link className="hover:underline" to={getLeaderboardsUrl(region, riotUserName, riotTagLine)}>
            <span className="text-lavender-50">
              Ladder Rank <strong className="text-white">{numberWithCommas(ladderRanking)}</strong> (top {rankPercentile}%)
            </span>
          </Link>
        </TooltipContainer>
      )}
    </div>
  );
}
