import React, { useEffect, useRef, useState } from "react";
import gql from "graphql-tag";
import classnames from "classnames";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { useLazyQuery } from "@apollo/client";
import { useSummonerProfileContext } from "@ugg/shared/components/SummonerProfiles/SummonerProfileContext";
import { ReactComponent as Search } from "@ugg/shared/assets/svg/search-icon.svg";
import { ReactComponent as X } from "@ugg/shared/assets/svg/x.svg";

const FETCH_PLAYED_WITH = gql`
  query FetchPlayedWith($regionId: String!, $riotUserName: String!, $riotTagLine: String!) {
    fetchPlayedWith(regionId: $regionId, riotUserName: $riotUserName, riotTagLine: $riotTagLine) {
      riotIds {
        tagLine
        username
      }
    }
  }
`;

interface FetchPlayedWithData {
  fetchPlayedWith: {
    riotIds: Array<{ tagLine: string; username: string }>;
  };
}

type MultiSearchOption<T = any> = {
  value: T;
  name: string;
  onClick: (option: MultiSearchOption<T>) => void;
  render: React.ReactNode;
};

interface MultiSearchProps {
  filters: {
    championId: number | "all";
    duoRiotId: (string & {}) | "all";
  };
  onSummonerFilterChange: (type: string, option: { value: (string & {}) | "all" }) => void;
  onChampionFilterChange: (type: string, option: { value: number | "all" }) => void;
}

export default function MultiSearch(props: MultiSearchProps) {
  const { filters, onSummonerFilterChange, onChampionFilterChange } = props;
  const { riotUserName, riotTagLine, region } = useSummonerProfileContext();
  const [getSummoners, { loading, data }] = useLazyQuery<FetchPlayedWithData>(FETCH_PLAYED_WITH, {
    ssr: false,
    variables: {
      regionId: region,
      riotUserName,
      riotTagLine,
    },
  });

  const { fetchPlayedWith } = data || {};
  const { riotIds } = fetchPlayedWith || {};

  const { getChampionImg, getChampionName, useChampionMini } = getRiotAssetsContext();
  const { data: championMini } = useChampionMini({ ssr: true });

  const [searchValue, setSearchValue] = useState("");

  const [currentSearchOption, setCurrentSearchOption] = useState<MultiSearchOption>();
  const [inputActive, setInputActive] = useState(false);
  const [highlightedItem, setHighlightedItem] = useState(0);

  const [savedWidth, setSavedWidth] = useState<number>();

  const multiSearchRef = useRef<HTMLDivElement>(null);
  const multiSearchLabelRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const activeFilters = {
    champion: filters.championId !== "all" && filters.championId > 0,
    summoner: filters.duoRiotId && filters.duoRiotId !== "all",
  };
  const activeFiltersLength = Object.values(activeFilters).filter((value) => !!value).length;

  useEffect(() => {
    if (inputActive) {
      getSummoners();
      inputRef.current?.focus();
    } else {
      setSearchValue("");
      setCurrentSearchOption(undefined);
    }
  }, [inputActive]);

  useEffect(() => {
    const rect = multiSearchLabelRef.current?.getBoundingClientRect();
    if (rect) {
      setSavedWidth(rect.right - rect.left);
    }
  }, [JSON.stringify(activeFilters)]);

  const filterOptions = (options: Array<MultiSearchOption>) =>
    options
      .filter(({ name }) => {
        const normalizedChampionName = name.toLowerCase().replace(/[^A-Za-z0-9]/g, "");
        const normalizedInput = searchValue.toLowerCase().replace(/[^A-Za-z0-9]/g, "");
        return normalizedChampionName.startsWith(normalizedInput);
      })
      .slice(...(currentSearchOption === undefined ? [0, 4] : [0]));

  const searchOptions = filterOptions([
    {
      value: "championId",
      name: "Champion",
      onClick: (option) => {
        setCurrentSearchOption(option);
        inputRef.current?.focus();
      },
      render: (
        <>
          <div>
            Champion: <span>Ezreal</span>
          </div>
        </>
      ),
    },
    {
      value: "playedWith",
      name: "Played With",
      onClick: (option) => {
        setCurrentSearchOption(option);
        inputRef.current?.focus();
      },
      render: (
        <>
          <div>
            Played With: <span>Doublelift</span>
          </div>
        </>
      ),
    },
  ]);

  const championOptions = filterOptions(
    ((championMini && Object.values(championMini)) || [])
      .sort((a, b) => a.name.localeCompare(b.name))
      .map<MultiSearchOption<number | "all">>(({ key, name }) => ({
        value: Number(key),
        name,
        onClick: (option) => onChampionFilterChange("championId", { value: option.value }),
        render: (
          <div className="flex items-center">
            <img src={getChampionImg(key)} />
            <div>{getChampionName(key)}</div>
          </div>
        ),
      })),
  );

  const summonerOptions = filterOptions(
    (riotIds || [])
      .map<MultiSearchOption<string>>((playedWith) => {
        const name = `${playedWith.username} #${playedWith.tagLine}`;
        return {
          name,
          value: name,
          onClick: (option) => onSummonerFilterChange("duoRiotId", { value: option.value }),
          render: <div>{name}</div>,
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name)),
  );

  const allOptions = [...searchOptions, ...championOptions, ...summonerOptions];

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Backspace" && searchValue.length === 0) {
      setCurrentSearchOption(undefined);
    }

    if (e.key === "Enter") {
      allOptions[highlightedItem].onClick(allOptions[highlightedItem]);
    }

    if (e.key === "ArrowUp") {
      e.preventDefault();
      setHighlightedItem(Math.abs(allOptions.length + highlightedItem - 1) % allOptions.length);
    }

    if (e.key === "ArrowDown") {
      e.preventDefault();
      setHighlightedItem(Math.abs(allOptions.length + highlightedItem + 1) % allOptions.length);
    }
  };

  useEffect(() => {
    if (window && inputRef.current && inputActive) {
      const outsideClick = (e: MouseEvent) => {
        if (
          multiSearchRef.current &&
          e.target !== null &&
          !multiSearchRef.current.contains(e.target as HTMLElement) &&
          document.contains(e.target as HTMLElement)
        ) {
          setInputActive(false);
        }
      };
      window.addEventListener("click", outsideClick);

      return () => window.removeEventListener("click", outsideClick);
    }
  }, [inputActive]);

  const renderOptions = (options: MultiSearchOption[], highlightOffset: number) =>
    options.map((option, index) => (
      <div
        key={option.value}
        className={classnames("multi-search-menu-item", highlightOffset + index === highlightedItem && "highlighted")}
        onMouseEnter={() => setHighlightedItem(highlightOffset + index)}
        onClick={() => option.onClick(option)}
      >
        {option.render}
      </div>
    ));

  const renderTags = (
    <>
      {activeFilters.champion && (
        <div className="multi-search-tag">
          {`Champion: ${getChampionName(filters.championId)}`}
          <X className="x-icon" onClick={() => onChampionFilterChange("championId", { value: "all" })} />
        </div>
      )}
      {activeFilters.summoner && (
        <div className="multi-search-tag">
          {`Played With: ${filters.duoRiotId}`}
          <X className="x-icon" onClick={() => onSummonerFilterChange("duoRiotId", { value: "all" })} />
        </div>
      )}
    </>
  );

  return (
    <div className="multi-search" ref={multiSearchRef}>
      <div
        className="multi-search-label"
        onClick={() => setInputActive(true)}
        ref={multiSearchLabelRef}
        style={{
          width: inputActive ? `${savedWidth}px` : "auto",
        }}
      >
        {inputActive && currentSearchOption && (
          <div className="multi-search-tag">
            {currentSearchOption.name}
            <X
              className="x-icon"
              onClick={() => {
                setCurrentSearchOption(undefined);
                inputRef.current?.focus();
              }}
            />
          </div>
        )}
        {activeFiltersLength === 0 || inputActive ? (
          <input
            placeholder={
              currentSearchOption === undefined
                ? "Search Champion or Played With..."
                : currentSearchOption.value === "championId"
                ? "Search Champion..."
                : "Search Played With..."
            }
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              setHighlightedItem(0);
            }}
            onKeyDown={onKeyDown}
            ref={inputRef}
          />
        ) : (
          renderTags
        )}
        <Search className="search-icon" />
      </div>
      {inputActive && (
        <div className="multi-search-menu">
          {activeFiltersLength > 0 && <div className="multi-search-menu-tags">{renderTags}</div>}

          {currentSearchOption === undefined && (
            <>
              {searchOptions.length > 0 && (
                <div className={classnames("multi-search-menu-title", activeFiltersLength > 0 ? "divider" : "")}>
                  SEARCH OPTIONS
                </div>
              )}
              {renderOptions(searchOptions, 0)}
            </>
          )}

          {(currentSearchOption === undefined || currentSearchOption.value === "championId") && (
            <>
              {championOptions.length > 0 && (
                <div
                  className={classnames(
                    "multi-search-menu-title",
                    (currentSearchOption !== undefined && activeFiltersLength === 0) ||
                      activeFiltersLength + searchOptions.length === 0
                      ? ""
                      : "divider",
                  )}
                >
                  CHAMPIONS
                </div>
              )}
              {renderOptions(
                championOptions,
                currentSearchOption && currentSearchOption.value === "championId" ? 0 : searchOptions.length,
              )}
            </>
          )}

          {(currentSearchOption === undefined || currentSearchOption.value === "playedWith") && (
            <>
              {summonerOptions.length > 0 && (
                <div
                  className={classnames(
                    "multi-search-menu-title",
                    (currentSearchOption !== undefined && activeFiltersLength === 0) ||
                      activeFiltersLength + searchOptions.length + championOptions.length === 0
                      ? ""
                      : "divider",
                  )}
                >
                  PLAYED WITH
                </div>
              )}
              {loading ? (
                <div style={{ padding: "20px" }}>
                  <div className="spinthatshit-loader">
                    <div className="spinner"></div>
                  </div>
                </div>
              ) : (
                renderOptions(
                  summonerOptions,
                  currentSearchOption && currentSearchOption.value === "playedWith"
                    ? 0
                    : searchOptions.length + championOptions.length,
                )
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}
