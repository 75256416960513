import { window } from "global";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import moment from "moment";
import "moment-duration-format";
import { Transition } from "react-transition-group";
import { MediaQuery } from "@outplayed/responsive";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { SummonerSpell, Rune } from "@outplayed/tooltips";
import { getOldProfileOverviewUrl, getProfileOverviewUrl } from "@ugg/shared/routes/app-routes";
import { normalizePatch } from "@ugg/shared/utils/patch-helpers";
import { getQueueTypeName } from "@ugg/shared/utils/queue-type-helpers";
import { calculateKDA, getKdaColor } from "@ugg/shared/utils/kda";
import { sortTeamByRoles } from "@ugg/shared/utils/role-helpers";
import MultikillIndicator from "./MultikillIndicator";
import MatchSummaryLPDisplay from "./MatchSummaryLPDisplay";
import ItemsList from "@ugg/shared/components/common/ItemsList";
import { ReactComponent as DropdownArrowRed } from "@ugg/shared/assets/svg/dropdown-arrow-red.svg";
import { ReactComponent as DropdownArrowBlue } from "@ugg/shared/assets/svg/dropdown-arrow-blue.svg";
import { ReactComponent as DropdownArrowGrey } from "@ugg/shared/assets/svg/dropdown-arrow-grey.svg";

import { MatchSummary, TeamData } from "@ugg/shared/api/requests/summoner-profiles/match-summaries";
import { MatchLP } from "../overview/match-history/match-history-helpers";

interface MatchSummaryContainerProps {
  data: MatchSummary;
  winConditionClass: string;
  matchLP: MatchLP;
  isNew?: boolean;
  isExpanded?: boolean;
  setIsExpanded?: (bool: boolean) => void;
  disableDropdown?: boolean;
}

interface MatchSummaryChildProps extends MatchSummaryContainerProps {
  className?: string;
  calculatedProps: {
    gameDuration: string;
    csPerMinute: number;
    itemList: number[];
    multiKillType: string | null;
    showVisionScore: boolean;
    remake: boolean;
    matchEndTime: number;
    queueTypeString: string;
    isRanked: boolean;
    fromNow: string;
    victoryStatus: string;
    normalizedPatch: string;
    kdaRatio: string;
    kdaColor: string;
    teams: readonly [TeamData[], TeamData[]];
    keystone: number;
    lpInfo: MatchSummary["lpInfo"];
    maximumKillStreak: MatchSummary["maximumKillStreak"];
  };
}

export default function MatchSummary(props: MatchSummaryContainerProps) {
  const { getRuneKeystoneId } = getRiotAssetsContext();
  const { data } = props;

  if (!data) {
    return null;
  }

  const {
    queueType,
    matchCreationTime,
    win,
    matchDuration,
    version,
    runes,
    assists,
    kills,
    deaths,
    cs,
    jungleCs,
    items,
    teamA,
    teamB,
    maximumKillStreak,
    lpInfo,
  } = data;

  const itemList = useMemo(() => {
    const list: number[] = Array(6).fill(0);
    items.forEach((e, i) => {
      if (e !== 0 && i < items.length - 1) {
        list[i] = e;
      }
    });
    list.push(items[items.length - 1]);
    return list;
  }, [data]);

  const matchEndTime = matchCreationTime + matchDuration * 1000;
  const remake = matchDuration <= 270;
  const kdaRatio = calculateKDA(kills || 0, deaths || 0, assists || 0);
  const normalizedPatch = normalizePatch(version);
  let queueTypeString = getQueueTypeName(queueType);
  if (queueTypeString === "Ultimate Spellbook") {
    queueTypeString = "Ult Spellbook";
  }

  const calculatedProps = {
    gameDuration: moment.duration(matchDuration, "seconds").format("mm:ss"),
    csPerMinute: Math.round(((cs + jungleCs) / (matchDuration / 60)) * 10) / 10,
    itemList,
    multiKillType: [null, "double", "triple", "quadra", "penta"][Math.min(maximumKillStreak - 1, 4)],
    showVisionScore: !["normal_aram"].includes(queueType),
    remake,
    matchEndTime,
    queueTypeString,
    isRanked: queueType === "ranked_solo_5x5" || queueType === "ranked_flex_sr",
    fromNow: moment(matchEndTime).locale("match").fromNow(),
    victoryStatus: remake ? "REMAKE" : win ? "WIN" : "LOSS",
    normalizedPatch,
    kdaRatio,
    kdaColor: getKdaColor(kdaRatio) || "",
    teams: [teamA, teamB] as const,
    keystone: getRuneKeystoneId(runes, { patch: normalizedPatch }),
    lpInfo,
    maximumKillStreak,
  };

  return (
    <React.Fragment>
      <MediaQuery min="MOBILE_SMALL" max="MOBILE_MEDIUM">
        <MobileMatchSummary {...props} calculatedProps={calculatedProps} />
      </MediaQuery>
      <MediaQuery min="MOBILE_LARGE" max="DESKTOP_LARGE">
        <DesktopMatchSummary {...props} calculatedProps={calculatedProps} />
      </MediaQuery>
    </React.Fragment>
  );
}

const MobileMatchSummary = (props: MatchSummaryChildProps) => {
  const { getChampionImg, getChampionName } = getRiotAssetsContext();
  const { className, data, calculatedProps, setIsExpanded, disableDropdown } = props;
  const { lpInfo, version } = data;
  const { itemList, normalizedPatch } = calculatedProps;

  const expandMatch = (e: React.MouseEvent & { outplayed_tooltip_clicked?: boolean }) => {
    if (!disableDropdown && !e.outplayed_tooltip_clicked && e.currentTarget.contains(e.target as HTMLElement)) {
      setIsExpanded && setIsExpanded(!props.isExpanded);
    }
    e.outplayed_tooltip_clicked = false;
  };

  const matchSummaryClassNames = classnames("match-summary", "match-summary_mobile", props.winConditionClass, className);
  return (
    <div className={matchSummaryClassNames} onClick={expandMatch}>
      {props.isNew && <div className="new-game-indicator" />}
      <div className="content-container">
        <div className="match-outcome">
          <div className="flex items-center win-lp-stat">
            <div className="victory-status">{calculatedProps.victoryStatus}</div>
            {calculatedProps.isRanked && (
              <MatchSummaryLPDisplay matchLP={props.matchLP} serverLPInfo={lpInfo as MatchLP} version={version} />
            )}
          </div>
          <div className="flex items-center queue-type-time">
            <div className="queue-type">{calculatedProps.queueTypeString}</div>
            <div className="from-now">{calculatedProps.fromNow}</div>
          </div>
        </div>
        <div className="match-details">
          <div className="champion-details">
            <div style={{ position: "relative", marginRight: 4 }}>
              <div className="champion-face">
                <img src={getChampionImg(data.championId)} alt={getChampionName(data.championId)} />
              </div>
              <div className="champion-level">{data.level}</div>
            </div>
            <span style={{ fontSize: "16px", color: "red", display: "flex" }}></span>
            <div className="summoner-spells">
              <div className="summoner-spell">{window && <SummonerSpell spellId={data.summonerSpells[0]} />}</div>
              <div className="summoner-spell">{window && <SummonerSpell spellId={data.summonerSpells[1]} />}</div>
            </div>
            <div className="runes">
              <div className="rune">{window && <Rune runeId={calculatedProps.keystone} patch={normalizedPatch} />}</div>
              <div className="rune rune_sub-style">{window && <Rune runeId={data.subStyle} />}</div>
            </div>
          </div>
          <div className="match-stats">
            <div className="flex items-center">
              <div className="multikill">
                <MultikillIndicator multiKillType={calculatedProps.multiKillType} disableText enableTooltip />
              </div>
              <div className="items">
                <ItemsList items={itemList} patch={normalizedPatch} dim={18} winConditionClass={props.winConditionClass} />
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div className="KDA-totals">
                {data.kills} <span className="slash">/</span> <span className="red">{data.deaths}</span>{" "}
                <span className="slash">/</span> {data.assists}
              </div>
              <div className="KDA-ratio" style={{ color: calculatedProps.kdaColor }}>
                {calculatedProps.kdaRatio} <span className="faded">KDA</span>
              </div>
              <div className="cs">
                {data.cs + data.jungleCs} <span className="faded">CS ({calculatedProps.csPerMinute})</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DesktopMatchSummary = (props: MatchSummaryChildProps) => {
  const { getChampionImg, getChampionName, getChampionImgFromSprite } = getRiotAssetsContext();
  const { className, data, calculatedProps, setIsExpanded, disableDropdown } = props;
  const { itemList, normalizedPatch } = calculatedProps;
  const { queueType, lpInfo, matchId } = data;
  const newTagTimeout = useRef<NodeJS.Timeout>();
  const [newTagNotification, setNewTagNotification] = useState(window && props.isNew);

  const showLP = calculatedProps.isRanked && !calculatedProps.remake;

  useEffect(() => {
    return () => {
      newTagTimeout.current && clearTimeout(newTagTimeout.current);
    };
  }, []);

  useEffect(() => {
    if (newTagNotification) {
      newTagTimeout.current && clearTimeout(newTagTimeout.current);
      newTagTimeout.current = setTimeout(() => {
        setNewTagNotification(false);
      }, 2000);
    }
  }, [newTagNotification]);

  const matchSummaryClassNames = classnames("match-summary", "match-summary_desktop", props.winConditionClass, className, {
    "dropdown-disabled": disableDropdown,
  });

  return (
    <div
      className={matchSummaryClassNames}
      onClick={() => !disableDropdown && setIsExpanded?.(!props.isExpanded)}
      data-match={matchId}
    >
      {props.isNew && <div className="new-game-indicator" />}
      <div className="content-container">
        <div className="group-one">
          <div className="row-one">
            <div className="queue-type">{calculatedProps.queueTypeString}</div>
            <div className="from-now">{calculatedProps.fromNow}</div>
          </div>
          <div className={classnames("row-two", { "row-two_spacing": !newTagNotification && !showLP })}>
            <Transition timeout={0} in={newTagNotification} appear={window && newTagNotification}>
              {(state) => (
                <div
                  className={classnames("new-match-notification-container", {
                    [`new-match-notification-container_${state}`]: !showLP,
                  })}
                >
                  <Transition timeout={0} in={newTagNotification} appear>
                    {(state) => <div className={`new-match-tag new-match-tag_${state}`}>NEW</div>}
                  </Transition>
                  <Transition timeout={0} in={!newTagNotification} appear={window && newTagNotification}>
                    {(state) =>
                      showLP && (
                        <div className={`lp-display_transition lp-display_transition_${state}`}>
                          <MatchSummaryLPDisplay
                            matchLP={props.matchLP}
                            serverLPInfo={lpInfo as MatchLP}
                            version={data.version}
                          />
                        </div>
                      )
                    }
                  </Transition>
                </div>
              )}
            </Transition>
          </div>
          <div className="row-three">
            <div className="victory-status">{calculatedProps.victoryStatus}</div>&nbsp;&nbsp;
            <div className="game-duration">{calculatedProps.gameDuration}</div>
          </div>
        </div>
        <div className="group-two">
          <div className="row-one">
            <div className="champion">
              <div className="champion-face">
                <img src={getChampionImg(data.championId)} alt={getChampionName(data.championId)} />
                <div className="champion-level">{data.level}</div>
              </div>
            </div>
            <div className="summoner-spells">
              <div className="summoner-spell">{window && <SummonerSpell spellId={data.summonerSpells[0]} />}</div>
              <div className="summoner-spell">{window && <SummonerSpell spellId={data.summonerSpells[1]} />}</div>
            </div>
            <div className="runes">
              <div className="rune">{window && <Rune runeId={calculatedProps.keystone} patch={normalizedPatch} />}</div>
              <div className="rune rune_sub-style">{window && <Rune runeId={data.subStyle} />}</div>
            </div>
          </div>
          {Math.min(calculatedProps.maximumKillStreak, 4) >= 3 && (
            <div className="multi-kill">
              <MultikillIndicator multiKillType={calculatedProps.multiKillType} />
            </div>
          )}
        </div>
        <div className="post-stats">
          <div className="KDA-totals">
            {data.kills} <span className="slash">/</span> <span className="red">{data.deaths}</span>{" "}
            <span className="slash">/</span> {data.assists}
          </div>
          <div className="KDA-ratio" style={{ color: calculatedProps.kdaColor }}>
            {calculatedProps.kdaRatio} <span className="faded">KDA</span>
          </div>
          <div className="cs">
            {data.cs + data.jungleCs} <span className="faded">CS ({calculatedProps.csPerMinute})</span>
          </div>
          {!isNaN(data.visionScore) && calculatedProps.showVisionScore && (
            <div className="vision-value">
              <span>{data.visionScore}</span>&nbsp;<span>vision</span>
            </div>
          )}
        </div>
        <div className="group-three">
          <div className="items">
            <div className="main-items">
              <ItemsList
                items={itemList.slice(0, itemList.length - 1)}
                patch={normalizedPatch}
                dim={22}
                winConditionClass={props.winConditionClass}
              />
            </div>
            <div className="trinket-icon">
              <ItemsList
                items={[itemList[itemList.length - 1]]}
                patch={normalizedPatch}
                dim={22}
                winConditionClass={props.winConditionClass}
              />
            </div>
          </div>
        </div>
        <div className="group-four">
          {calculatedProps.teams.map((team, index) => {
            const sortedTeam = sortTeamByRoles(team.slice()) as TeamData[];
            return (
              <div className="team-list" key={index}>
                {sortedTeam.map(({ championId, riotUserName, riotTagLine, summonerName }, index) => {
                  let isProfileSummonerClass = "";
                  if (
                    (data.riotUserName &&
                      riotUserName === data.riotUserName &&
                      data.riotUserName &&
                      riotTagLine === data.riotTagLine) ||
                    (data.summonerName && summonerName === data.summonerName)
                  ) {
                    isProfileSummonerClass = data["win"] ? "chosen_win" : "chosen_loss";
                  }
                  return (
                    <div className={`summoner-entry ${isProfileSummonerClass}`} key={index}>
                      <div className="champion-face">{getChampionImgFromSprite(championId, { size: 17 })}</div>
                      <div className="summoner-name">
                        <Link
                          title={riotUserName && riotTagLine ? `${riotUserName} #${riotTagLine}` : summonerName || ""}
                          to={
                            riotUserName && riotTagLine
                              ? getProfileOverviewUrl(data.regionId, riotUserName, riotTagLine)
                              : getOldProfileOverviewUrl(data.regionId, summonerName || "")
                          }
                          onClick={(e) => e.stopPropagation()}
                        >
                          {riotUserName && riotTagLine ? riotUserName : summonerName}
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      <div
        className={classnames("icons-container", props.winConditionClass, {
          "is-expanded": props.isExpanded,
          invisible: disableDropdown,
        })}
      >
        <div className={classnames("expand-match-icon", { "is-expanded": props.isExpanded })}>
          {props.winConditionClass === "match_lose" ? (
            <DropdownArrowRed />
          ) : props.winConditionClass === "match_remake" ? (
            <DropdownArrowGrey />
          ) : (
            <DropdownArrowBlue />
          )}
        </div>
      </div>
    </div>
  );
};
