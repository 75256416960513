import { window } from "global";
import { useState, useEffect, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import moment from "moment";
import "moment-duration-format";
import classNames from "classnames";
import { MediaQuery } from "@outplayed/responsive";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { getOldProfileOverviewUrl, getProfileOverviewUrl } from "@ugg/shared/routes/app-routes";
import { normalizePatch } from "@ugg/shared/utils/patch-helpers";
import { getQueueTypeName } from "@ugg/shared/utils/queue-type-helpers";
import { calculateKDA, getKdaColor } from "@ugg/shared/utils/kda";
import ItemsList from "@ugg/shared/components/common/ItemsList";

import { MatchSummary, TeamData } from "@ugg/shared/api/requests/summoner-profiles/match-summaries";
import { ArenaAugment } from "@outplayed/tooltips";

import { ReactComponent as DropdownArrowBlue } from "@ugg/shared/assets/svg/dropdown-arrow-blue.svg";
import { ReactComponent as DropdownArrowRed } from "@ugg/shared/assets/svg/dropdown-arrow-red.svg";
import { ReactComponent as DropdownArrowGrey } from "@ugg/shared/assets/svg/dropdown-arrow-grey.svg";

function mergeTeams(teamA: TeamData[], teamB: TeamData[]) {
  let [winningTeamArray, losingTeamArray]: [ArenaTeamExtraInfo[][], ArenaTeamExtraInfo[][]] = [[], []];
  const teamLength = teamA.length + teamB.length;

  for (let i = 1; i <= 8; i++) {
    const teamSummoners: ArenaTeamExtraInfo[] = [];
    let subTeamId: number = 0;
    for (const team of [teamA, teamB]) {
      for (const member of team) {
        if (member.placement === i) {
          subTeamId = member.playerSubteamId;
          teamSummoners.push({
            summonerName: member.summonerName,
            riotUserName: member.riotUserName,
            riotTagLine: member.riotTagLine,
            championId: member.championId,
            teamId: subTeamId,
            index: i,
          });
        }
      }
    }
    if (teamSummoners.length) {
      i <= Math.floor(teamLength / 4) ? winningTeamArray.push(teamSummoners) : losingTeamArray.push(teamSummoners);
    }
  }

  return [winningTeamArray, losingTeamArray];
}

type ArenaTeamExtraInfo = {
  summonerName: string | null;
  riotUserName: string | null;
  riotTagLine: string | null;
  championId: number;
  teamId: number;
  index: number;
};

interface MatchSummaryContainerProps {
  data: MatchSummary;
  winConditionClass: string;
  isNew?: boolean;
  isExpanded?: boolean;
  setIsExpanded?: (bool: boolean) => void;
}

interface MatchSummaryChildProps extends MatchSummaryContainerProps {
  className?: string;
  calculatedProps: {
    gameDuration: string;
    csPerMinute: number;
    itemList: number[];
    multiKillType: string | null;
    showVisionScore: boolean;
    remake: boolean;
    matchEndTime: number;
    queueTypeString: string;
    isRanked: boolean;
    fromNow: string;
    victoryStatus: string;
    normalizedPatch: string;
    kdaRatio: string;
    kdaColor: string;
    teams: readonly [TeamData[], TeamData[]];
    keystone: number;
    lpInfo: MatchSummary["lpInfo"];
    maximumKillStreak: MatchSummary["maximumKillStreak"];
    placement: number;
  };
}

export default function ArenaMatchSummary(props: MatchSummaryContainerProps) {
  const { getRuneKeystoneId } = getRiotAssetsContext();
  const { data } = props;
  if (!data) {
    return null;
  }

  const {
    queueType,
    matchCreationTime,
    win,
    matchDuration,
    version,
    runes,
    assists,
    kills,
    deaths,
    cs,
    jungleCs,
    items,
    teamA,
    teamB,
    maximumKillStreak,
    lpInfo,
    summonerName,
    riotUserName,
    riotTagLine,
  } = data;

  const placement = useMemo(() => {
    for (const team of [teamA, teamB]) {
      const currentPlayer = team.find(
        (player) =>
          (player.summonerName && summonerName && player.summonerName === summonerName) ||
          (riotUserName && riotTagLine && player.riotUserName === riotUserName && player.riotTagLine === riotTagLine),
      );
      if (currentPlayer !== undefined) {
        return currentPlayer.placement;
      }
    }
    return 0;
  }, [data]);

  const itemList = useMemo(() => {
    const list: number[] = Array(6).fill(0);
    items.forEach((e, i) => {
      if (e !== 0 && i < items.length - 1) {
        list[i] = e;
      }
    });
    list.push(items[items.length - 1]);
    return list;
  }, [data]);

  const matchEndTime = matchCreationTime + matchDuration * 1000;
  const remake = matchDuration <= 270;
  const kdaRatio = calculateKDA(kills || 0, deaths || 0, assists || 0);
  const normalizedPatch = normalizePatch(version);
  const calculatedProps = {
    gameDuration: moment.duration(matchDuration, "seconds").format("mm:ss"),
    csPerMinute: Math.round(((cs + jungleCs) / (matchDuration / 60)) * 10) / 10,
    itemList,
    multiKillType: [null, "double", "triple", "quadra", "penta"][Math.min(maximumKillStreak - 1, 4)],
    showVisionScore: !["normal_aram"].includes(queueType),
    remake,
    matchEndTime,
    queueTypeString: getQueueTypeName(queueType),
    isRanked: queueType === "ranked_solo_5x5" || queueType === "ranked_flex_sr",
    fromNow: moment(matchEndTime).locale("match").fromNow(),
    victoryStatus: remake ? "REMAKE" : win ? "WIN" : "LOSS",
    normalizedPatch,
    kdaRatio,
    kdaColor: getKdaColor(kdaRatio) || "",
    teams: [teamA, teamB] as const,
    keystone: getRuneKeystoneId(runes, { patch: normalizedPatch }),
    lpInfo,
    maximumKillStreak,
    placement: placement,
  };

  return (
    <>
      <MediaQuery min="MOBILE_SMALL" max="MOBILE_MEDIUM">
        <MobileArenaMatchSummary {...props} calculatedProps={calculatedProps} />
      </MediaQuery>
      <MediaQuery min="MOBILE_LARGE" max="DESKTOP_LARGE">
        <DesktopArenaMatchSummary {...props} calculatedProps={calculatedProps} />
      </MediaQuery>
    </>
  );
}

const MobileArenaMatchSummary = (props: MatchSummaryChildProps) => {
  const { getChampionImg, getChampionName, getChampionImgFromSprite } = getRiotAssetsContext();
  const { className, data, calculatedProps } = props;
  const { itemList, normalizedPatch } = calculatedProps;

  const result = data.win ? "win" : "loss";
  const augments = data?.augments || [0, 0, 0, 0];
  const arenaMatchSummaryClassNames = classnames(
    "arena-match-summary",
    "arena-match-summary_mobile",
    props.winConditionClass,
    className,
  );
  const newTagTimeout = useRef<NodeJS.Timeout>();
  const [newTagNotification, setNewTagNotification] = useState(window && props.isNew);

  useEffect(() => {
    return () => {
      newTagTimeout.current && clearTimeout(newTagTimeout.current);
    };
  }, []);

  useEffect(() => {
    if (newTagNotification) {
      newTagTimeout.current && clearTimeout(newTagTimeout.current);
      newTagTimeout.current = setTimeout(() => {
        setNewTagNotification(false);
      }, 2000);
    }
  }, [newTagNotification]);

  //Create teams variable for player row
  const finalTeamArray = mergeTeams(data.teamA, data.teamB);
  const win = data.win ? "win" : "loss";

  return (
    <div className={arenaMatchSummaryClassNames}>
      <div className={`post-game-container`} onClick={() => props.setIsExpanded && props.setIsExpanded(!props.isExpanded)}>
        {props.isNew && <div className="new-game-indicator" />}
        <div className="match-info">
          <span className="result" style={{ color: win === "win" ? "#3273fa" : "#ff4e50" }}>
            {win === "win" ? "WIN" : "LOSS" + " "}
          </span>
          <div style={{ position: "relative", top: "2px" }}>
            <span className="arena">Arena</span>
            <span className="timestamp">{calculatedProps.fromNow}</span>
          </div>
        </div>
        <div className="match-container">
          <div className="champion-info">
            <div className="champion">
              <div className="champion-face">
                <img src={getChampionImg(data.championId)} alt={getChampionName(data.championId)} />
                <div className="champion-level">{data.level}</div>
              </div>
            </div>
            <div className="augments">
              {augments.map((augment: number, index: number) => (
                <div className={`augment-holder ${result}`} key={index}>
                  <ArenaAugment className="augment-image" augmentId={augment} patch={normalizedPatch} />
                </div>
              ))}
            </div>
          </div>
          <div className="match-stats">
            <div className="champion-items">
              <div className="items">
                <ItemsList items={itemList} patch={normalizedPatch} dim={18} />
              </div>
            </div>
            <div className="kda-stats">
              <div className="KDA-totals">
                {data.kills}
                <span className={`slash ${result}`}>{" / "}</span>
                <span className="red">{data.deaths}</span>
                <span className={`slash ${result}`}>{" / "}</span>
                {data.assists}
              </div>
              <div className="KDA-ratio" style={{ color: calculatedProps.kdaColor }}>
                {calculatedProps.kdaRatio} <span className="faded">KDA</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DesktopArenaMatchSummary = (props: MatchSummaryChildProps) => {
  const { getChampionImg, getChampionName, getChampionImgFromSprite } = getRiotAssetsContext();
  const { className, data, calculatedProps } = props;
  const { itemList, normalizedPatch } = calculatedProps;
  const placements = ["1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th"];

  const result = data.win ? "win" : "loss";
  const augments = data?.augments || [0, 0, 0, 0];
  const arenaMatchSummaryClassNames = classnames(
    "arena-match-summary",
    "arena-match-summary_desktop",
    props.winConditionClass,
    className,
  );
  const newTagTimeout = useRef<NodeJS.Timeout>();
  const [newTagNotification, setNewTagNotification] = useState(window && props.isNew);

  useEffect(() => {
    return () => {
      newTagTimeout.current && clearTimeout(newTagTimeout.current);
    };
  }, []);

  useEffect(() => {
    if (newTagNotification) {
      newTagTimeout.current && clearTimeout(newTagTimeout.current);
      newTagTimeout.current = setTimeout(() => {
        setNewTagNotification(false);
      }, 2000);
    }
  }, [newTagNotification]);

  //Create teams variable for player row
  const [winningTeamArray, losingTeamArray] = mergeTeams(data.teamA, data.teamB);
  const win = data.win ? "win" : "loss";

  return (
    <div className={arenaMatchSummaryClassNames}>
      <div
        className={`post-game-container`}
        onClick={() => props.setIsExpanded && props.setIsExpanded(!props.isExpanded)}
        data-match={data.matchId}
      >
        {props.isNew && <div className="new-game-indicator" />}
        <div className="match-info">
          <span className="arena">Arena</span>
          <span className="timestamp">{calculatedProps.fromNow}</span>
          <div className="placement">{placements[calculatedProps.placement - 1]}</div>
          <div className="result-time">
            <span className="result" style={{ color: win === "win" ? "#3273fa" : "#ff4e50" }}>
              {win === "win" ? "WIN" : "LOSS" + " "}
            </span>
            <span className="time">{calculatedProps.gameDuration}</span>
          </div>
        </div>
        <div className="champion-info">
          <div className="champion">
            <div className="champion-face">
              <img src={getChampionImg(data.championId)} alt={getChampionName(data.championId)} />
              <div className="champion-level">{data.level}</div>
            </div>
          </div>
          <div className="augments">
            {augments.map((augment: number, index: number) => (
              <div className={`augment-holder ${result}`} key={index}>
                <ArenaAugment className="augment-image" augmentId={augment} patch={normalizedPatch} />
              </div>
            ))}
          </div>
        </div>
        <div className="champion-stats">
          <div className="KDA-totals">
            {data.kills}
            <span className={`slash ${result}`}>{" / "}</span>
            <span className="red">{data.deaths}</span>
            <span className={`slash ${result}`}>{" / "}</span>
            {data.assists}
          </div>
          <div className="KDA-ratio" style={{ color: calculatedProps.kdaColor }}>
            {calculatedProps.kdaRatio} <span className="faded">KDA</span>
          </div>
          <div className="champion-items">
            <div className="items">
              <div className="main-items">
                <ItemsList items={itemList.slice(0, itemList.length - 1)} patch={normalizedPatch} dim={18} />
              </div>
              <div className="trinket-icon">
                <ItemsList items={[itemList[itemList.length - 1]]} patch={normalizedPatch} dim={18} />
              </div>
            </div>
          </div>
        </div>
        <div className="all-players">
          <div className="winning-teams">
            <div className="label">WIN</div>
            <div
              className={classNames({
                "team-grid-format": winningTeamArray.length > 2,
                "duo-grid-format": winningTeamArray.length <= 2,
              })}
            >
              {winningTeamArray.map((team: ArenaTeamExtraInfo[], index: number) => {
                return (
                  <div className="team-list" key={index}>
                    {team.map(({ championId, riotUserName, riotTagLine, summonerName }, index) => {
                      let isProfileSummonerClass = "";
                      if (
                        (data.riotUserName &&
                          riotUserName === data.riotUserName &&
                          data.riotUserName &&
                          riotTagLine === data.riotTagLine) ||
                        (data.summonerName && summonerName === data.summonerName)
                      ) {
                        isProfileSummonerClass = data["win"] ? "chosen_win" : "chosen_loss";
                      }
                      return (
                        <div className={`summoner-entry ${isProfileSummonerClass}`} key={index}>
                          <div className="champion-face">{getChampionImgFromSprite(championId, { size: 12 })}</div>
                          <div className="summoner-name">
                            <Link
                              title={riotUserName && riotTagLine ? `${riotUserName} #${riotTagLine}` : summonerName || ""}
                              to={
                                riotUserName && riotTagLine
                                  ? getProfileOverviewUrl(data.regionId, riotUserName, riotTagLine)
                                  : getOldProfileOverviewUrl(data.regionId, summonerName || "")
                              }
                              onClick={(e) => e.stopPropagation()}
                            >
                              {riotUserName && riotTagLine ? riotUserName : summonerName}
                            </Link>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="losing-teams">
            <div className="label">LOSS</div>
            <div
              className={classNames({
                "team-grid-format": winningTeamArray.length > 2,
                "duo-grid-format": winningTeamArray.length <= 2,
              })}
            >
              {losingTeamArray.map((team: ArenaTeamExtraInfo[], index: number) => {
                return (
                  <div className="team-list" key={index}>
                    {team.map(({ championId, riotUserName, riotTagLine, summonerName }, index) => {
                      let isProfileSummonerClass = "";
                      if (
                        (data.riotUserName &&
                          riotUserName === data.riotUserName &&
                          data.riotUserName &&
                          riotTagLine === data.riotTagLine) ||
                        (data.summonerName && summonerName === data.summonerName)
                      ) {
                        isProfileSummonerClass = data["win"] ? "chosen_win" : "chosen_loss";
                      }
                      return (
                        <div className={`summoner-entry ${isProfileSummonerClass}`} key={index}>
                          <div className="champion-face">{getChampionImgFromSprite(championId, { size: 12 })}</div>
                          <div className="summoner-name">
                            <Link
                              title={riotUserName && riotTagLine ? `${riotUserName} #${riotTagLine}` : summonerName || ""}
                              to={
                                riotUserName && riotTagLine
                                  ? getProfileOverviewUrl(data.regionId, riotUserName, riotTagLine)
                                  : getOldProfileOverviewUrl(data.regionId, summonerName || "")
                              }
                              onClick={(e) => e.stopPropagation()}
                            >
                              {riotUserName && riotTagLine ? riotUserName : summonerName}
                            </Link>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`dropdown-button ${result} ${props.winConditionClass}`}
        onClick={() => props.setIsExpanded && props.setIsExpanded(!props.isExpanded)}
      >
        {props.winConditionClass === "match_lose" ? (
          <DropdownArrowRed style={{ transform: props.isExpanded ? "rotate(180deg)" : "" }} />
        ) : props.winConditionClass === "match_remake" ? (
          <DropdownArrowGrey style={{ transform: props.isExpanded ? "rotate(180deg)" : "" }} />
        ) : (
          <DropdownArrowBlue style={{ transform: props.isExpanded ? "rotate(180deg)" : "" }} />
        )}
      </div>
    </div>
  );
};
