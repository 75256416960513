import { useEffect, useMemo } from "react";
import classnames from "classnames";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { useTable, useSortBy, Column } from "react-table";
import { LPGainArrows } from "@ugg/shared/components/SummonerProfiles/overview/lp/LPGainArrows";
import { NoTableData } from "@ugg/shared/components/common/NoTableData";
import { TooltipContainer } from "@outplayed/tooltips";
import { numberWithCommas } from "@ugg/shared/utils/math";
import { getKdaColorClassName } from "@ugg/shared/utils/kda";
import { getWinRateBestChampsColorClassName } from "@ugg/shared/utils/win-rates";
import { formatSummonerChampionStats, ChampionStatsInfo } from "@ugg/shared/api/data-parser/summoners/summoner-champion-stats";
import { ReactComponent as Penta } from "@ugg/shared/assets/svg/multikills/penta.svg";
import { ReactComponent as Quadra } from "@ugg/shared/assets/svg/multikills/quadra.svg";
import { ReactComponent as Triple } from "@ugg/shared/assets/svg/multikills/triple.svg";
import { ReactComponent as Double } from "@ugg/shared/assets/svg/multikills/double.svg";
import { BasicChampionPerformance } from "@ugg/shared/api/requests/summoner-profiles/player-statistics";

interface ChampionStatsTableProps {
  data: BasicChampionPerformance[];
  isRanked?: boolean;
  hideLP?: boolean;
}

export function ChampionStatsTable(props: ChampionStatsTableProps) {
  const { getChampionImg, getChampionName } = getRiotAssetsContext();

  const { data, isRanked, hideLP } = props;

  const tableData = useMemo(() => {
    const champions = formatSummonerChampionStats(data || []);
    champions.sort((a, b) => {
      const matchDiff = b.played.totalMatches - a.played.totalMatches;
      if (matchDiff > 0) {
        return 1;
      } else if (matchDiff < 0) {
        return -1;
      } else {
        const champNameA = getChampionName(a.championId);
        const champNameB = getChampionName(b.championId);
        return champNameA.localeCompare(champNameB);
      }
    });

    return champions;
  }, [data]);

  const hiddenColumns = [(!isRanked || hideLP) && "lpAvg"].filter((col) => !!col).map((col) => String(col));

  const columns = useMemo<Column<ChampionStatsInfo>[]>(
    () => [
      {
        id: "rank",
        accessor: "totalMatches",
        Header: <span>Rank</span>,
        responsiveClassName: "min-w-[30px] max-w-[60px]",
        headerClassName: "rank-cell max-xs:[&>*]:hidden",
        className: "rank-cell",
        Cell: ({ index }) => <span>{index + 1}</span>,
        sortType: (a, b) => {
          const matchDiff = b.values.totalMatches - a.values.totalMatches;
          if (matchDiff > 0) {
            return 1;
          } else if (matchDiff < 0) {
            return -1;
          } else {
            const champNameA = getChampionName(a.values.championId);
            const champNameB = getChampionName(b.values.championId);
            return champNameA.localeCompare(champNameB);
          }
        },
      },
      {
        accessor: "championId",
        Header: "Champion",
        responsiveClassName:
          "min-w-[120px] max-[530px]:min-w-[70px] max-xs:min-w-[100px] max-[530px]:min-w-[70px] max-[530px]:min-w-[70px]",
        headerClassName: "champion-cell",
        className: "champion-cell",
        sortType: (a, b) => {
          const champNameA = getChampionName(a.values.championId);
          const champNameB = getChampionName(b.values.championId);
          return champNameA.localeCompare(champNameB);
        },
        Cell: (props) => {
          const championImg = getChampionImg(props.value);
          const championName = getChampionName(props.value);

          return (
            <div className="flex items-center w-full max-[530px]:justify-center">
              <div className="relative flex-none w-[30px] h-[30px] rounded-[3px] overflow-hidden max-xs:w-[24px] max-xs:h-[24px]">
                <img className="absolute w-full h-full scale-[1.1]" src={championImg} />
              </div>
              <span className="text-white ml-[12px] text-[13px] font-bold truncate max-[530px]:hidden">{championName}</span>
            </div>
          );
        },
      },
      {
        accessor: "winRate",
        Header: "Win Rate",
        responsiveClassName: "min-w-[110px] max-w-[140px]",
        headerClassName: "win-rate-cell",
        className: "win-rate-cell",
        sortDescFirst: true,
        Cell: ({ row: { original } }) => {
          const roundedWinRate = Math.round(original.winRate);
          const winRateColor = getWinRateBestChampsColorClassName(roundedWinRate);
          return (
            <div className="flex-1 w-full max-w-[100px] text-center">
              <strong className={classnames(winRateColor)}>{`${roundedWinRate}%`}</strong>
              <span className="text-lavender-300">{" / "}</span>
              <span className="text-[12px] font-medium text-lavender-50 text-center">
                {original.played.wins}W {original.played.losses}L
              </span>
            </div>
          );
        },
      },
      {
        accessor: "kda",
        Header: "KDA",
        responsiveClassName: "min-w-[110px] max-w-[120px]",
        headerClassName: "kda-cell",
        className: "kda-cell",
        sortDescFirst: true,
        sortType: (a, b) => {
          return a.values.kda.ratio - b.values.kda.ratio;
        },
        Cell: ({ value }) => (
          <div className="flex flex-col items-center">
            <div className={getKdaColorClassName(value.ratio)}>
              <strong>{value.ratio}</strong>
            </div>
            <span className="text-[11px] text-lavender-50">
              <strong>{value.avgKills}</strong>
              <span className="text-lavender-300"> / </span>
              <strong>{value.avgDeaths}</strong>
              <span className="text-lavender-300"> / </span>
              <strong>{value.avgAssists}</strong>
            </span>
          </div>
        ),
      },
      {
        accessor: "lpAvg",
        Header: () => (
          <div>
            <div>LP</div>
            <div>Gain</div>
          </div>
        ),
        responsiveClassName: classnames("min-w-[55px] max-w-[70px] max-sm:!hidden"),
        headerClassName: "lp-gain-cell",
        className: "lp-gain-cell",
        sortDescFirst: true,
        sortType: (a, b) => {
          return a.values.lpAvg - b.values.lpAvg;
        },
        Cell: (props) => <LPGainArrows className="text-white font-bold text-[12px]" lpAvg={props.value} />,
      },
      {
        accessor: "maxKills",
        Header: () => (
          <div>
            <div>Max</div>
            <div>Kills</div>
          </div>
        ),
        responsiveClassName: "min-w-[55px] max-w-[70px] max-[660px]:!hidden",
        headerClassName: "max-kills-cell",
        className: "max-kills-cell",
        sortDescFirst: true,
        Cell: (props) => <span>{props.value}</span>,
      },
      {
        Header: () => (
          <div>
            <div>Max</div>
            <div>Deaths</div>
          </div>
        ),
        responsiveClassName: "min-w-[55px] max-w-[70px] max-[660px]:!hidden",
        headerClassName: "max-deaths-cell",
        className: "max-deaths-cell",
        accessor: "maxDeaths",
        sortDescFirst: true,
        Cell: (props) => <span>{props.value}</span>,
      },
      {
        accessor: "avgCs",
        Header: "CS",
        responsiveClassName: "min-w-[70px] max-w-[80px] max-[450px]:!hidden",
        headerClassName: "cs-cell",
        className: "cs-cell",
        sortDescFirst: true,
        Cell: (props) => <span>{props.value}</span>,
      },
      {
        accessor: "avgDamage",
        Header: "Damage",
        responsiveClassName: "min-w-[70px] max-w-[80px] max-[800px]:!hidden",
        headerClassName: "damage-cell",
        className: "damage-cell",
        sortDescFirst: true,
        Cell: (props) => <span>{numberWithCommas(props.value)}</span>,
      },
      {
        accessor: "avgGold",
        Header: "Gold",
        responsiveClassName: "min-w-[70px] max-w-[80px] max-[800px]:!hidden",
        headerClassName: "gold-cell",
        className: "gold-cell",
        sortDescFirst: true,
        Cell: (props) => <span>{numberWithCommas(props.value)}</span>,
      },
      {
        accessor: "doubleKills",
        Header: () => (
          <TooltipContainer tooltipInfo="Double Kill">
            <div className="flex items-center justify-center w-[16px] h-[16px] rounded-[50%] bg-purple-600">
              <Double className="flex-none w-[10px]" />
            </div>
          </TooltipContainer>
        ),
        responsiveClassName: "min-w-[45px] max-w-[45px] max-[1075px]:!hidden",
        headerClassName: "multikill-cell",
        className: "multikill-cell",
        sortDescFirst: true,
        Cell: (props) => {
          return props.value === 0 ? (
            <span style={{ color: "#4F4F60" }}>&mdash;</span>
          ) : (
            <strong style={{ color: "#cddcfe" }}>{numberWithCommas(props.value)}</strong>
          );
        },
      },
      {
        accessor: "tripleKills",
        Header: () => (
          <TooltipContainer tooltipInfo="Triple Kill">
            <div className="flex items-center justify-center w-[16px] h-[16px] rounded-[50%] bg-purple-600">
              <Triple className="flex-none w-[10px]" />
            </div>
          </TooltipContainer>
        ),
        responsiveClassName: "min-w-[45px] max-w-[45px] max-[1075px]:!hidden",
        headerClassName: "multikill-cell",
        className: "multikill-cell",
        sortDescFirst: true,
        Cell: (props) => {
          return props.value === 0 ? (
            <span style={{ color: "#4F4F60" }}>&mdash;</span>
          ) : (
            <strong style={{ color: "#59d2d9" }}>{numberWithCommas(props.value)}</strong>
          );
        },
      },
      {
        accessor: "quadraKills",
        Header: () => (
          <TooltipContainer tooltipInfo="Quadra Kill">
            <div className="flex items-center justify-center w-[16px] h-[16px] rounded-[50%] bg-purple-600">
              <Quadra className="flex-none w-[10px]" />
            </div>
          </TooltipContainer>
        ),
        responsiveClassName: "min-w-[45px] max-w-[45px] max-[1075px]:!hidden",
        headerClassName: "multikill-cell",
        className: "multikill-cell",
        sortDescFirst: true,
        Cell: (props) => {
          return props.value === 0 ? (
            <span style={{ color: "#4F4F60" }}>&mdash;</span>
          ) : (
            <strong style={{ color: "#00a7ff" }}>{numberWithCommas(props.value)}</strong>
          );
        },
      },
      {
        accessor: "pentaKills",
        Header: () => (
          <TooltipContainer tooltipInfo="Penta Kill">
            <div className="flex items-center justify-center w-[16px] h-[16px] rounded-[50%] bg-purple-600">
              <Penta className="flex-none w-[10px]" />
            </div>
          </TooltipContainer>
        ),
        responsiveClassName: "min-w-[45px] max-w-[45px] max-[1075px]:!hidden",
        headerClassName: "multikill-cell",
        className: "multikill-cell",
        sortDescFirst: true,
        Cell: (props) => {
          return props.value === 0 ? (
            <span style={{ color: "#4F4F60" }}>&mdash;</span>
          ) : (
            <strong style={{ color: "#FF9B00" }}>{numberWithCommas(props.value)}</strong>
          );
        },
      },
    ],
    [data],
  );

  const columnStyle = () => ({
    flex: `1 1 100%`,
  });

  const { state, getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setHiddenColumns } = useTable(
    {
      columns,
      data: tableData,
      disableSortRemove: true,
      initialState: {
        hiddenColumns,
      },
    },
    useSortBy,
  );

  useEffect(() => {
    setHiddenColumns(hiddenColumns);
  }, [data, isRanked, hideLP]);

  if (rows.length === 0) {
    return (
      <div className="content-section">
        <NoTableData className="my-[48px]" />
      </div>
    );
  }

  return (
    <div {...getTableProps({ className: "content-section ReactTable ugg-table-2 w-full" })}>
      <div className="rt-thead -header">
        {headerGroups.map((headerGroup, index) => (
          <div {...headerGroup.getHeaderGroupProps({ className: "rt-tr" })}>
            {headerGroup.headers.map((column, index) => {
              const thClassNames = classnames([
                "rt-th",
                column.headerClassName,
                column.responsiveClassName,
                column.isSorted && !column.isSortedDesc ? "-sort-asc" : "",
                column.isSorted && column.isSortedDesc ? "-sort-desc" : "",
              ]);

              return (
                <div className={thClassNames} {...column.getHeaderProps(column.getSortByToggleProps({ style: columnStyle() }))}>
                  {column.render("Header")}
                </div>
              );
            })}
          </div>
        ))}
      </div>
      <div {...getTableBodyProps({ className: "rt-tbody" })}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <div key={i} className="rt-tr-group">
              <div {...row.getRowProps({ className: "rt-tr" })}>
                {row.cells.map((cell, index) => {
                  const tdClassNames = classnames("rt-td", cell.column.responsiveClassName, cell.column.className, {
                    sorted: index !== 0 && cell.column.isSorted,
                    "is-in-odd-row": i % 2 === 0,
                  });

                  return (
                    <div {...cell.getCellProps({ className: tdClassNames, style: columnStyle() })}>
                      {cell.render("Cell", { index: i })}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
